export const mapStreamCarousel = (stream, matureContentMinAge) => {
  return {
    id: stream.id,
    age_rating: {
      id: stream.age_rating?.id ?? null,
      name: stream.age_rating?.name ?? null,
      min_age: stream.age_rating?.min_age ?? null,
      image: stream.age_rating?.image?.content_url ?? null,
    },
    content_type: 'stream',
    description_html: stream.description_html ?? '',
    description_plain: stream.description_plain ?? '',
    display: {
      img: false,
      matureMsg: matureContentMinAge < stream.age_rating?.min_age,
      live: false,
      model: false,
      msg: false,
      video: false,
    },
    dynamic_shop_now_cta: stream.dynamic_shop_now_cta,
    future_schedule: stream.future_schedule,
    live_schedule: stream.live_schedule,
    loading: false,
    manifest: undefined,
    msg: undefined,
    pdp_url: stream.pdp_url,
    platform_details: {
      channel: stream.platform_details.channel,
      embed_url: stream.platform_details.embed_url,
      platform: stream.platform_details.platform,
    },
    purchase_info_enabled: stream.purchase_info_enabled,
    sku: {
      id: stream.sku.id,
      carousel_image: stream.sku.carousel_image.content_url ?? null,
      game_pass_xbox: stream.sku.game_pass_xbox,
      game_pass_pc: stream.sku.game_pass_pc,
      media_objects: undefined,
      poster_image: stream.sku.poster_image?.content_url ?? null,
      sku_titles: stream.sku.sku_titles ?? null,
      tags: stream.sku.tags,
      title: stream.sku.title,
    },
    shop_now_cta: stream.shop_now_cta,
    status: {
      live: undefined,
      future: undefined,
      is_past: undefined,
      on_demand: undefined,
    },
    stream_descriptions: stream.stream_descriptions,
    stream_shop_now_ctas: stream.stream_shop_now_ctas,
    stream_type: {
      name: stream.stream_type.name,
      platform: stream.stream_type.platform.name,
    },
    title: stream.title,
    video_live_src: undefined,
    video_src: undefined,
  };
};
