export default {
  // Dutch
  ageGate: 'Deze inhoud is bestemd voor volwassenen.',
  ageGateL2: 'Ga alleen verder als je bevoegd bent.',
  ageGateError: `Helaas, je hebt niet de juiste leeftijd om deze inhoud te bekijken.`,
  ageSubmission:
    'Door je leeftijd op te geven, bevestig je dat je oud genoeg bent om deze inhoud te bekijken',
  ageGateConfirm: 'Ben je ouder dan {minAge}?',
  ageGateConfirmMessage:
    'Deze content is voor een volwassen publiek. Controleer uw leeftijd om door te gaan. ',
  ageGateConfirmMinAge: 'Ja, ik ben {minAge}+',
  ageGateDenyMinAge: 'Nee',
  audioEffect: 'Audio-effect: ',
  autoScrollOff: 'Autoscroll op',
  autoScrollOn: 'Autoscroll uit',
  autotranslate: '(Automatisch vertaald met behulp van AI)',
  accessories: 'Accessoires',
  captions: 'Onderschriften',
  close: 'Sluiten',
  cookieBannerTextP1: `We gebruiken cookies voor deze video-ervaring om anoniem gebruikersactiviteit te analyseren. Door op Akkoord te klikken, geef je toestemming voor het gebruik van deze cookies. Zie ons `,
  cookieBannerPrivacyText: 'Privacybeleid ',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/nl-nl/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: 'voor meer informatie.',
  cookieBannerAccept: 'Akkoord',
  cookieBannerDeny: 'Nee, bedankt',
  copilot: 'Copiloot',
  copy: 'Kopiëren',
  dateOfBirth: 'Voer je geboortedatum in',
  day: 'Dag',
  description: 'Beschrijving',
  details: 'Beschriving',
  devices: 'Enheter',
  explore: 'Verkennen',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Game-informatie',
  isoCode: 'nl',
  keyFeatures: 'Belangrijkste Kenmerken',
  language: 'Taal',
  learnMore: 'Meer informatie',
  live: 'Live',
  matureContentMessage: 'Game art kan alleen worden bekeken voor dit spel.',
  m365: 'M365',
  month: 'Maand',
  months: {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mrt',
    apr: 'Apr',
    may: 'Mei',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Okt',
    nov: 'Nov',
    dec: 'Dec',
  },
  nextSlide: 'Naar volgende dia',
  notLive: 'Deze stream is nog niet live.',
  openTranscript: 'Transcript openen',
  otherProducts: 'Andere producten',
  outdatedBrowser:
    'Helaas, het lijkt erop dat je browser is verouderd. Werk nu bij om de laatste streams van Mixer te bekijken.',
  playVideo: 'Video afspelen',
  previousSlide: 'Naar vorige dia',
  poweredByAzure: 'Aangedreven door Azure',
  products: 'Producten',
  productInformation: 'Productinformatie',
  promotions: 'Promoties',
  purchaseInfo: 'Aankoopinformatie',
  readMore: 'Meer informatie',
  reload: 'herladen',
  reloadToTryAgain: 'Herlaad om het opnieuw te proberen',
  selectDay: 'Selecteer dag',
  selectMonth: 'Selecteer maand',
  selectYear: 'Selecteer jaar',
  share: 'Delen',
  shareDescription: 'Deel deze media-ervaring en online pagina met iemand die je kent.',
  shareDialogTitle: 'Bekijk deze geweldige functies die beschikbaar zijn met {title}!',
  shopGame: '{title} kopen',
  shopNow: 'Nu winkelen',
  shopSku: '{title} kopen',
  showMore: 'Meer weergeven',
  showLess: 'Minder weergeven',
  showcase: 'Presenteren',
  streamEnded: 'Deze stream is beëindigd. Er wordt momenteel inhoud op aanvraag geüpload.',
  timeline: 'Tijdlijn',
  timeStampJumpTo: 'Ga naar',
  timeStampMore: 'Meer',
  tips: 'Tips',
  unknownError: 'Oeps! Er is iets misgegaan tijdens het laden van de videospeler',
  video: 'video',
  voice: 'Stem: ',
  view: 'Bekijken',
  watchNow: 'Nu bekijken',
  windows: 'Windows 11',
  year: 'Jaar',
};
