export default {
  // Norway
  ageGate: 'Dette innholdet er for et modent publikum.',
  ageGateL2: 'Fortsett bare hvis du er kvalifisert.',
  ageGateError: `Beklager, du er ikke i alderen til å se dette innholdet.`,
  ageSubmission:
    'Ved å sende inn alderen din bekrefter du at du er gammel nok til å se dette innholdet',
  ageGateConfirm: 'Er du over {minAge}?',
  ageGateConfirmMessage:
    'Dette innholdet er for et modent publikum. Kontroller alderen din for å fortsette.',
  ageGateConfirmMinAge: 'Ja, jeg er {minAge}+',
  ageGateDenyMinAge: 'Nei',
  audioEffect: 'Lydeffekt: ',
  autoScrollOff: 'Automatisk utrullering av',
  autoScrollOn: 'Automatisk krysn på',
  autotranslate: '(Automatisk oversatt ved hjelp av kunstig intelligens)',
  accessories: 'Tilbehør',
  captions: 'Billedtekster',
  close: 'Lukke',
  cookieBannerTextP1: `Vi bruker informasjonskapsler på denne videoopplevelsen til å analysere brukeraktivitet anonymt. Ved å klikke på "Jeg godtar" samtykker du til bruken av disse informasjonskapslene. Se våre`,
  cookieBannerPrivacyText: 'retningslinjer for personvern ',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/nb-no/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: 'for å lese mer.',
  cookieBannerAccept: 'Jeg godtar',
  cookieBannerDeny: 'Nei takk',
  copilot: 'Copilot',
  copy: 'Kopi',
  dateOfBirth: 'Skriv inn fødselsdatoen din',
  day: 'Dag',
  description: 'Beskrivelse',
  details: 'Detaljer',
  devices: 'Enheter',
  explore: 'Utforske',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Informasjon om spill',
  isoCode: 'no',
  keyFeatures: 'Viktige Funksjoner',
  language: 'Språk',
  learnMore: 'Få mer informasjon',
  live: 'Leve',
  matureContentMessage: 'Spillkunst kan bare sees for dette spillet.',
  m365: 'M365',
  month: 'Måned',
  months: {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'Mai',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Okt',
    nov: 'Nov',
    dec: 'Des',
  },
  nextSlide: 'Gå til neste lysbilde',
  notLive: 'Denne strømmen er ikke direkte ennå.',
  openTranscript: 'Åpne transkripsjon',
  otherProducts: 'Andre produkter',
  outdatedBrowser:
    'Oops, det ser ut som nettleseren din er utdatert. Oppdater nå for å se de nyeste strømmene fra Mixer.',
  playVideo: 'Spill av video',
  poweredByAzure: 'Drevet av Azure',
  previousSlide: 'Gå til forrige lysbilde',
  products: 'Produkter',
  productInformation: 'Produkt informasjon',
  promotions: 'Kampanjer',
  purchaseInfo: 'Informasjon om kjøp',
  readMore: 'Les mer',
  reload: 'Reload',
  reloadToTryAgain: 'Last inn på nytt for å prøve på nytt',
  selectDay: 'Velg dag',
  selectMonth: 'Velg måned',
  selectYear: 'Velg år',
  share: 'Aksje',
  shareDescription: 'Del denne medieopplevelsen og nettsiden med noen du kjenner.',
  shareDialogTitle: 'Ta en titt på disse fantastiske funksjonene som er tilgjengelige med {title}!',
  shopGame: 'Kjøp {title}',
  shopNow: 'Kjøp nå',
  shopSku: 'Kjøp {title}',
  showMore: 'Vis mer',
  showLess: 'Vis mindre',
  showcase: 'Presentasjon',
  streamEnded: 'Denne dataflyten er avsluttet. Behovsbetinget innhold lastes opp.',
  timeline: 'Tidslinje',
  timeStampJumpTo: 'Hopp til',
  timeStampMore: 'Mer',
  tips: 'Tips',
  unknownError: 'Oops! Noe gikk galt under lasting av videospilleren',
  video: 'video',
  voice: 'Stemme: ',
  view: 'Utsikt',
  watchNow: 'Se nå',
  windows: 'Windows 11',
  year: 'År',
};
