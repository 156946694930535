export const mapRetailer = retailer => {
  return {
    id: retailer.id,
    age_gate_format: retailer.age_gate_format?.text,
    age_rating_board: retailer.age_rating_board,
    anchoring_enabled: retailer.anchoring_enabled,
    autoplay_enabled: retailer.autoplay_enabled,
    cookie_banner_enabled: retailer.cookie_banner_enabled,
    cookie_opt_out: retailer.cookie_opt_out_enabled,
    country: retailer.country,
    iframe_enabled: retailer.modular_iframe_enabled,
    locale: retailer.primary_language.code_region,
    localeName: retailer.primary_language.name,
    matureContentMinAge: retailer.mature_content?.min_age,
    name: retailer.name,
    offline_image: retailer.offline_image.content_url,
    retailer_languages: retailer.retailer_languages,
    scrolling_iframe_enabled: retailer.scrolling_iframe_enabled,
    sharing_enabled: retailer.sharing_enabled,
    shop_now_cta_color: retailer.shop_now_cta_color,
    shop_now_cta_bg_color: retailer.shop_now_cta_bg_color,
    xbox_game_pass_link: retailer.xbox_game_pass_link,
  };
};
