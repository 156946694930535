export const mapGridBlock = params => {
  const { id, bgColor, gradientLeft, gradientRight, gridCards, title, titleColor } = params;
  return {
    id,
    bgColor,
    cards: gridCards.map(card => ({
      id: card.id,
      alt: card.image_alt_text,
      copy: card.copy,
      cta: {
        copy: card.cta_copy,
        link: card.cta_link,
      },
      image: card.image?.content_url,
      title: card.title,
    })),
    gradientLeft,
    gradientRight,
    title,
    titleColor,
  };
};
