import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';
import { createGtm } from '@gtm-support/vue-gtm';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import { detect } from 'detect-browser';
import VueSocialSharing from 'vue-social-sharing';
import '@/assets/js/plugins/lazysizes.js';
import '@/assets/js/plugins/resizemsghandler.js';
import '@/assets/js/plugins/smoothscroll.js';
import '@/assets/scss/global.scss';
import { createPlausible } from './assets/js/plugins/tracking';

const app = createApp(App);
const browser = detect();

// browser detection
if (browser && browser.name) {
  app.config.globalProperties.$browser = browser;
  document.documentElement.classList.add(browser.name);

  const touchEventsClass =
    browser.os === 'iOS' || browser.os === 'Android OS' ? 'touchevents' : 'no-touchevents';
  ('no-touchevents');
  app.config.globalProperties.$touchEvents = touchEventsClass === 'touchevents';
  document.documentElement.classList.add(touchEventsClass);
}

// iframe detection
if (window.location !== window.parent.location) {
  app.config.globalProperties.$iframe = true;
  document.documentElement.classList.add('iframe');
} else {
  app.config.globalProperties.$iframe = false;
  document.documentElement.classList.add('no-iframe');
}

// Custom dropdown close directive
app.directive('close-dropdown', {
  mounted(el, binding) {
    const handleClick = event => {
      if (!el.contains(event.target) && el !== event.target) binding.value(event);
    };
    el.clickEventHandler = handleClick;
    document.addEventListener('click', handleClick);
  },
  beforeUnmount(el) {
    document.removeEventListener('click', el.clickEventHandler);
  },
});

// GTM config
app.use(
  createGtm({
    id: 'GTM-KRZB889',
    defer: false,
    enabled: false,
  })
);

const plausible = createPlausible({
  init: {
    domain: process.env.VUE_APP_PARENT_URL,
    trackLocalhost: process.env.VUE_APP_ENV === 'local',
  },
  settings: {
    enableAutoPageviews: false,
    enableAutoOutboundTracking: true,
  },
});

app
  .use(i18n)
  .use(createMetaManager())
  .use(metaPlugin)
  .use(store)
  .use(router)
  .use(VueSocialSharing)
  .use(plausible)
  .mount('#app');
