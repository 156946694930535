import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    beforeEnter: (to, from, next) => {
      if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
        next({ name: '404' });
      } else {
        next();
      }
    },
  },
  {
    path: '/translations',
    name: 'Translations',
    component: () => import(/* webpackChunkName: "translations" */ '../views/TranslationsView.vue'),
    beforeEnter: (to, from, next) => {
      if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
        next({ name: '404' });
      } else {
        next();
      }
    },
  },
  {
    path: '/:retailer/modals/stream/:stream',
    name: 'Stream',
    component: () => import(/* webpackChunkName: "stream" */ '../views/StreamView.vue'),
  },
  {
    path: '/:retailer/landing/:stream',
    name: 'Landing',
    props: true,
    component: () => import(/* webpackChunkName: "landing" */ '../views/LandingView.vue'),
  },
  {
    path: '/:retailer/modals/live-stream',
    name: 'LiveStream',
    component: () => import(/* webpackChunkName: "livestream" */ '../views/LiveView.vue'),
  },
  {
    path: '/:retailer/modals/live-m365',
    name: 'LiveM365',
    component: () => import(/* webpackChunkName: "liveM365" */ '../views/LiveM365View.vue'),
  },
  {
    path: '/:retailer/modals/live-windows',
    name: 'LiveWindows',
    component: () => import(/* webpackChunkName: "liveWindows" */ '../views/LiveWindowsView.vue'),
  },
  {
    path: '/:retailer/modals/qr',
    name: 'QrModel',
    component: () => import(/* webpackChunkName: "qr" */ '../views/QrModelView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "error" */ '../views/ErrorView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
