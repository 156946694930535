const state = () => ({});

const mutations = {
  setStream(state, data) {
    delete data.initial;
    Object.assign(state, data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
