import isAboveAge from '@/assets/js/utils/is-above-age';

const state = () => ({
  mature: {
    17: undefined,
    18: undefined,
  },
  matureAges: ['17', '18'],
});

const getters = {
  showAgeGate(state, getters, rootState) {
    const { min_age } = rootState.stream.age_rating || 0;
    const { mature, matureAges } = state;
    return matureAges.includes(min_age?.toString()) && !mature[min_age];
  },

  showAgeGateError(state, getters, rootState) {
    const { min_age } = rootState.stream.age_rating || 0;
    const { mature } = state;
    return mature[min_age] === undefined ? false : !mature[min_age];
  },
};

const mutations = {
  setMature(state, ageGateData) {
    const { userDate = null, minAge = null, isAboveMinAge = false } = ageGateData;

    // Check user's age against all mature ages
    const updatedState = { ...state.mature };
    state.matureAges.forEach(years => {
      if (userDate) {
        updatedState[years] = isAboveAge({ userDate, years });
      } else {
        if (updatedState[years] !== undefined) return;

        if (isAboveMinAge) {
          if (minAge >= years) {
            updatedState[years] = isAboveMinAge;
          }
        } else {
          if (years >= minAge) {
            updatedState[years] = isAboveMinAge;
          }
        }
      }
    });
    // Update state
    state.mature = { ...updatedState };
  },

  setCanViewStream(state, res) {
    state.canViewStream = res;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
