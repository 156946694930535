export const mapBucketCarousel = bucketData => {
  const { bucketItem, bucketType, contentType, locale, ageRatingBoard } = bucketData;
  return {
    id: bucketItem.sku.id,
    ...(bucketType === 'gaming' && {
      ageRatingEndpoint: bucketItem.sku[`${ageRatingBoard}_age_rating`]
        ? bucketItem.sku[`${ageRatingBoard}_age_rating`].substring(4)
        : null,
    }),
    buy_now: '',
    carousel_image: bucketItem.sku.carousel_image.content_url,
    content_type: contentType,
    description:
      bucketItem.sku.language_descriptions.find(d => d.language.code_region === locale)
        ?.description || '',
    display: {
      img: false,
      matureMsg: false,
      live: false,
      model: false,
      msg: false,
      video: false,
    },
    future_schedule: [],
    live_schedule: [],
    loading: false,
    manifest: undefined,
    sku: {
      title: '',
      media_objects: [{}],
      poster_image: bucketItem.sku.poster_image?.content_url ?? null,
      ...(bucketType === 'explore' && {
        ar_glb_content_url: bucketItem.sku.ar_glb?.content_url ?? '',
        ar_usdz_content_url: bucketItem.sku.ar_usdz?.content_url ?? '',
        ar_hdr_content_url: bucketItem.sku.ar_hdr?.content_url ?? '',
      }),
      ...(bucketType === 'gaming' && { tags: bucketItem.sku.tags }),
    },
    sku_titles: bucketItem.sku.sku_titles,
    status: {
      live: false,
    },
    title: bucketItem.sku.title,
    video_live_src: undefined,
    video_src: undefined,
  };
};
