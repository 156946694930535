import { mapGridBlock } from '@/api/mappers/gridBlock.js';

export const mapLandingStream = stream => {
  return {
    id: stream.id,
    carouselBlock:
      stream.landing_page.carousel.gridBlockCards4.length > 0
        ? mapGridBlock({
            id: 'carouselBlock',
            bgColor: stream.landing_page.carousel.grid4BgColor,
            gradientLeft: stream.landing_page.carousel.grid4GradientLeft,
            gradientRight: stream.landing_page.carousel.grid4GradientRight,
            gridCards: stream.landing_page.carousel.gridBlockCards4,
            title: stream.landing_page.carousel.grid4Title,
            titleColor: stream.landing_page.carousel.grid4TitleColor,
          })
        : null,
    carouselBlock2:
      stream.landing_page.carousel2.gridBlockCards3.length > 0
        ? mapGridBlock({
            id: 'carouselBlock2',
            bgColor: stream.landing_page.carousel2.grid3BgColor,
            gradientLeft: stream.landing_page.carousel2.grid3GradientLeft,
            gradientRight: stream.landing_page.carousel2.grid3GradientRight,
            gridCards: stream.landing_page.carousel2.gridBlockCards3,
            title: stream.landing_page.carousel2.grid3Title,
            titleColor: stream.landing_page.carousel2.grid3TitleColor,
          })
        : null,
    headerBlock:
      stream.landing_page.headerBlock.headerDesktopBgImage?.content_url ||
      stream.landing_page.headerBlock.headerMobileBgImage?.content_url ||
      stream.landing_page.headerBlock.headerDesktopBgVideo?.content_url ||
      stream.landing_page.headerBlock.headerMobileBgVideo?.content_url
        ? {
            cta: {
              bgColor: stream.landing_page.headerBlock.headerCtaBgColor,
              color: stream.landing_page.headerBlock.headerCtaTextColor,
              link: stream.landing_page.headerBlock.headerCtaLink,
              text: stream.landing_page.headerBlock.headerCtaText,
            },
            image: {
              desktop: stream.landing_page.headerBlock.headerDesktopBgImage?.content_url,
              mobile: stream.landing_page.headerBlock.headerMobileBgImage?.content_url,
            },
            subtitle: stream.landing_page.headerBlock.headerSubtitle,
            title: stream.landing_page.headerBlock.headerTitle,
            textColor: stream.landing_page.headerBlock.headerTextColor,
            video: {
              desktop: stream.landing_page.headerBlock.headerDesktopBgVideo?.content_url ?? null,
              mobile: stream.landing_page.headerBlock.headerMobileBgVideo?.content_url ?? null,
            },
          }
        : null,
    gridBlock:
      stream.landing_page.grid2.gridBlockCards2.length > 0
        ? mapGridBlock({
            id: 'grid',
            bgColor: stream.landing_page.grid2.grid2BgColor,
            gradientLeft: stream.landing_page.grid2.grid2GradientLeft,
            gradientRight: stream.landing_page.grid2.grid2GradientRight,
            gridCards: stream.landing_page.grid2.gridBlockCards2,
            title: stream.landing_page.grid2.grid2Title,
            titleColor: stream.landing_page.grid2.grid2TitleColor,
          })
        : null,
    bladeBlock:
      stream.landing_page['50-50'].gridBlockCards5.length > 0
        ? {
            bgColor: stream.landing_page['50-50'].grid5BgColor,
            cards: stream.landing_page['50-50'].gridBlockCards5.map(card => ({
              alt: card.image_alt_text,
              cta: {
                link: card.cta_link,
                text: card.cta_copy,
              },
              description: card.copy,
              image: card.image?.content_url,
              reverse: card.reverse_layout,
              title: card.title,
            })),
            gradientLeft: stream.landing_page['50-50'].grid5GradientLeft,
            gradientRight: stream.landing_page['50-50'].grid5GradientRight,
            title: stream.landing_page['50-50'].grid5Title,
            titleColor: stream.landing_page['50-50'].grid5TitleColor,
          }
        : null,
    settings: {
      bgColor: stream.landing_page.general.sidePaddingColor,
      container: stream.landing_page.general.enableSidePadding,
    },
    streamBlock: stream.landing_page.iframeBlock.iframeSelectedIframe
      ? {
          bgColor: stream.landing_page.iframeBlock.iframeBgColor,
          gradientLeft: stream.landing_page.iframeBlock.iframeBgColorGradientLeft,
          gradientRight: stream.landing_page.iframeBlock.iframeBgColorGradientRight,
          title: stream.landing_page.iframeBlock.iframeTitle,
          titleColor: stream.landing_page.iframeBlock.iframeTitleColor,
        }
      : null,
    stream_type: {
      name: stream.stream_type.name,
      platform: stream.stream_type.platform.name,
      platformId: stream.stream_type.platform.id,
    },
    title: stream.title,
  };
};
