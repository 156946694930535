export const deDupe = object => {
  return [...new Set(object.map(i => i.sku.id))].map(id => object.find(i => i.sku.id === id));
};

export const getVideoId = videoUrl => {
  return [...new Set(new URL(videoUrl).pathname.split('/'))].pop();
};

export const isValidUrl = urlString => {
  try {
    return Boolean(new URL(urlString));
  } catch {
    return false;
  }
};
