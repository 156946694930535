export const mapTimeline = timeline => {
  const mappedTimeline = [];
  const captions = timeline.split(/\r?\n/);
  captions.forEach((caption, index) => {
    if (!caption.includes('-->')) return;
    mappedTimeline.push({
      id: index,
      active: false,
      cue: captions[index + 1],
      end: caption
        .substring(18, 25)
        .split(':')
        .reduce((acc, time) => 60 * acc + +time),
      time: caption.substring(1, 8),
      timestamp: caption
        .substring(1, 8)
        .split(':')
        .reduce((acc, time) => 60 * acc + +time),
    });
  });
  return mappedTimeline;
};
