export const mapSkuM365 = params => {
  const {
    contentType,
    locale,
    liveStreamUrl,
    stream: { description_plain, sku, stream_descriptions },
  } = params;
  return {
    id: sku.id,
    buy_now: '',
    carousel_image: sku.carousel_image.content_url,
    content_type: contentType,
    description:
      stream_descriptions.find(d => d.retailer_language.language.code_region === locale)
        ?.description_plain ?? description_plain,
    display: {
      img: false,
      live: false,
      model: false,
      msg: false,
      video: false,
    },
    future_schedule: [],
    live_schedule: [],
    loading: false,
    manifest: undefined,
    sku: {
      title: '',
      media_objects: [{}],
      poster_image: sku.poster_image.content_url ?? null,
    },
    sku_titles: sku.sku_titles,
    status: {
      live: !!liveStreamUrl,
    },
    title: sku.title,
    video_live_src: liveStreamUrl ?? null,
    video_src: undefined,
  };
};
