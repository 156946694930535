import ar_AELocale from '@/assets/locales/ar_AE';
import da_DKLocale from '@/assets/locales/da_DK';
import de_DELocale from '@/assets/locales/de_DE';
import en_USLocale from '@/assets/locales/en_US';
import es_ESLocale from '@/assets/locales/es_ES';
import es_MXLocale from '@/assets/locales/es_MX';
import fi_FILocale from '@/assets/locales/fi_FI';
import fr_CALocale from '@/assets/locales/fr_CA';
import fr_FRLocale from '@/assets/locales/fr_FR';
import it_ITLocale from '@/assets/locales/it_IT';
import ja_JPLocale from '@/assets/locales/ja_JP';
import ko_KRLocale from '@/assets/locales/ko_KR';
import nb_NOLocale from '@/assets/locales/nb_NO';
import nl_NLLocale from '@/assets/locales/nl_NL';
import pl_PLLocale from '@/assets/locales/pl_PL';
import pt_BRLocale from '@/assets/locales/pt_BR';
import pt_PTLocale from '@/assets/locales/pt_PT';
import ru_RULocale from '@/assets/locales/ru_RU';
import sv_SELocale from '@/assets/locales/sv_SE';
import zh_CNLocale from '@/assets/locales/zh_CN';

const localeRefs = {
  'ar-AE': ar_AELocale,
  'da-DK': da_DKLocale,
  'de-DE': de_DELocale,
  'en-US': en_USLocale,
  'es-ES': es_ESLocale,
  'es-MX': es_MXLocale,
  'fi-FI': fi_FILocale,
  'fr-CA': fr_CALocale,
  'fr-FR': fr_FRLocale,
  'it-IT': it_ITLocale,
  'ja-JP': ja_JPLocale,
  'ko-KR': ko_KRLocale,
  'nb-NO': nb_NOLocale,
  'nl-NL': nl_NLLocale,
  'pl-PL': pl_PLLocale,
  'pt-BR': pt_BRLocale,
  'pt-PT': pt_PTLocale,
  'ru-RU': ru_RULocale,
  'sv-SE': sv_SELocale,
  'zh-CN': zh_CNLocale,
};

const state = () => ({
  locales: [
    'ar-AE',
    'da-DK',
    'de-DE',
    'en-US',
    'es-ES',
    'es-MX',
    'fi-FI',
    'fr-CA',
    'fr-FR',
    'it-IT',
    'ja-JP',
    'ko-KR',
    'nb-NO',
    'nl-NL',
    'pl-PL',
    'pt-BR',
    'pt-PT',
    'ru-RU',
    'sv-SE',
    'zh-CN',
  ],
  locale: 'en-US',
  messages: en_USLocale,
});

const mutations = {
  setLocale(state, data) {
    const { country, locale } = data;
    state.country = country;

    if (state.locales.includes(locale)) {
      state.locale = locale;
      state.messages = localeRefs[locale];
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
