import axios from 'axios';
import { mapAgeRating } from './mappers/ageRating.js';
import { mapBucketCarousel } from './mappers/bucketCarousel.js';
import { mapBucketCapacities } from './mappers/bucketCapacities.js';
import { mapBucketIds } from './mappers/bucketIds.js';
import { mapLandingStream } from './mappers/landingStream.js';
import { mapRetailer } from './mappers/retailer.js';
import { mapMedia } from './mappers/media.js';
import { mapStream } from './mappers/stream.js';
import { mapStreamCarousel } from './mappers/streamCarousel.js';
import { mapTimeline } from './mappers/timeline.js';

// config
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL + '/api';
axios.defaults.timeout = 16000;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

const apiToken = {
  headers: {
    'X-CLIENT-TOKEN': 'ejN0rLBmu4bThNbnORxXbVdn7VPUGVJ4TOPyqe28TzhrbWbgYXXBrLP',
  },
};

const getAgeRating = async endpoint => {
  const { data } = await axios.get(endpoint, apiToken);
  return data ? mapAgeRating(data) : null;
};

const getBucketIds = async id => {
  const { data } = await axios.get(`/buckets?retailer=${id}`, apiToken);
  return data.map(bucket => mapBucketIds(bucket));
};

const getBucketCapacities = async () => {
  const { data } = await axios.get('/settings/1', apiToken);
  return mapBucketCapacities(data);
};

const getBucketCarousel = async bucketData => {
  const { bucketId, page } = bucketData;
  const { data } = await axios.get(
    `/bucket_skus?bucket=${bucketId}&page=${page}&itemsPerPage=10&pagination=true&order[weight]=asc`,
    apiToken
  );

  return data.map(bucketItem =>
    mapBucketCarousel({
      bucketItem,
      ...bucketData,
    })
  );
};

const getBuyNowUrl = async (id, retailerId) => {
  const { data } = await axios.get(
    `/sku_buy_now_urls?sku.id=${id}&retailer.id=${retailerId}`,
    apiToken
  );
  return data.length ? data[0].url : '';
};

const getCarousel = async (params, retailerID, page, matureContentMinAge) => {
  const { data } = await axios.get(
    `/streams?retailer=${retailerID}${params}&page=${page}&itemsPerPage=10&pagination=true`,
    apiToken
  );
  return data.map(stream => mapStreamCarousel(stream, matureContentMinAge));
};

const getLiveStream = async (params, streamType, platform, matureContentMinAge) => {
  const { data } = await axios.get(
    `/streams?streamType.name=${streamType}&streamType.platform=${platform}&pagination=true&page=1&itemsPerPage=1${params}`,
    apiToken
  );
  return data.length ? mapStream(data[0], matureContentMinAge) : null;
};

const getPurchaseInfo = async (id, locale) => {
  const { data } = await axios.get(
    `video-purchase-infos?videoId=${id}&codeRegion=${locale}`,
    apiToken
  );
  return data.length > 0 ? data.map(p => ({ ...p, active: false })) : data;
};

const getRetailer = async id => {
  const { data } = await axios.get(`/retailers/${id}`, apiToken);
  return mapRetailer(data);
};

const getSkuMedia = async (id, country, isShowcase = false) => {
  let formattedLocale = country;
  if (formattedLocale === 'SV') formattedLocale = 'SE';
  const { data } = await axios.get(
    `/media_objects?sku=${id}&country=${formattedLocale}&order[weight]=asc`,
    apiToken
  );

  if (data.length) {
    const primaryMedia = data.find(item => item.primary_media) || data[0];
    return isShowcase
      ? data.map(media => mapMedia(media, 'media'))
      : [mapMedia(primaryMedia, 'media')];
  } else {
    return data;
  }
};

const getStream = async (id, matureContentMinAge) => {
  const { data } = await axios.get(`/streams/${id}`, apiToken);
  return mapStream(data, matureContentMinAge);
};

const getLandingStream = async (id, matureContentMinAge) => {
  const { data } = await axios.get(`/streams/${id}`, apiToken);

  return {
    landing: data ? mapLandingStream(data) : null,
    stream: data.landing_page.iframeBlock.iframeSelectedIframe
      ? mapStream(data.landing_page.iframeBlock.iframeSelectedIframe, matureContentMinAge)
      : null,
  };
};

const getTimestamps = async (url, retailerId, language) => {
  const { data } = await axios.get(
    `/timestamps?url=${url}&retailerId=${retailerId}&language=${language}`,
    apiToken
  );
  return data.length ? data.map(t => ({ ...t, active: false })) : data;
};

const getVideoManifest = async (id, locale) => {
  try {
    const [manifest, captions] = await Promise.all([
      axios.get(`/${id}/streaming-url?isBase=true&urlFormat=MPEG_DASH`, {
        baseURL: process.env.VUE_APP_VIDEO_INDEXER_API,
      }),
      axios.get(`/${id}/Captions?format=Vtt&language=${locale}&includeAudioEffects=true`, {
        baseURL: process.env.VUE_APP_VIDEO_INDEXER_API,
      }),
    ]);

    // Create timeline from captions data
    const timeline = mapTimeline(captions.data);

    return {
      ...manifest.data,
      mimeType: 'application/x-mpegURL',
      timeline,
    };
  } catch {
    return null;
  }
};

const postDiagnosticIssue = iframeIssue => {
  axios.post('/post-issue', iframeIssue, apiToken);
};

export default {
  getAgeRating,
  getBucketCapacities,
  getBucketCarousel,
  getBucketIds,
  getBuyNowUrl,
  getCarousel,
  getLandingStream,
  getLiveStream,
  getPurchaseInfo,
  getRetailer,
  getSkuMedia,
  getStream,
  getTimestamps,
  getVideoManifest,
  postDiagnosticIssue,
};
