export default {
  // Spanish
  ageGate: 'Este contenido está destinado a un público adulto.',
  ageGateL2: 'Continúa solamente si eres elegible.',
  ageGateError: `Lo sentimos, no tienes edad suficiente para ver este contenido.`,
  ageSubmission:
    'Al proporcionar tu edad, confirmas que tienes edad suficiente para ver este contenido',
  ageGateConfirm: '¿Eres mayor de {minAge} años?',
  ageGateConfirmMessage:
    'Este contenido es para un público maduro. Por favor, verifique su edad para continuar.',
  ageGateConfirmMinAge: 'Sí, soy {minAge}+',
  ageGateDenyMinAge: 'No',
  audioEffect: 'Efecto de audio: ',
  autoScrollOff: 'Desplazamiento automático desactivado',
  autoScrollOn: 'Desplazamiento automático activado',
  autotranslate: '(Traducido automáticamente usando IA)',
  accessories: 'Accesorios',
  captions: 'Subtítulos',
  close: 'Cerrar',
  cookieBannerTextP1: `Usamos cookies en esta experiencia de vídeo para analizar la actividad del usuario de manera anónima. Al seleccionar "Acepto", das tu consentimiento para el uso de estas cookies. Consulta nuestra `,
  cookieBannerPrivacyText: 'Directiva de privacidad ',
  cookieBannerPrivacyLink:
    'https://privacy.microsoft.com/es-es/privacystatement#maincookiessimilartechnologiesmodule',
  cookieBannerTextP2: 'para saber más.',
  cookieBannerAccept: 'Acepto',
  cookieBannerDeny: 'No gracias',
  copilot: 'Copiloto',
  copy: 'Dupdo',
  dateOfBirth: 'Especifica tu fecha de nacimiento',
  day: 'Día',
  description: 'Descripción',
  details: 'Detalles',
  devices: 'Dispositivos',
  explore: 'Explorar',
  gamepass: 'Xbox Game Pass',
  gameInformation: 'Información del juego',
  isoCode: 'es',
  keyFeatures: 'Características principales',
  language: 'Idioma',
  learnMore: 'Aprende más',
  live: 'En directo',
  matureContentMessage: 'El arte del juego solo se puede ver para este juego.',
  m365: 'M365',
  month: 'Mes',
  months: {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Abr',
    may: 'May',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Ago',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dic',
  },
  nextSlide: 'Ir a la diapositiva siguiente',
  notLive: 'Este contenido en streaming aún no está disponible.',
  openTranscript: 'Transcripción abierta',
  otherProducts: 'Otros productos',
  outdatedBrowser:
    'Vaya. Parece que tu navegador no está actualizado. Actualízalo ahora para mirar el contenido en streaming más reciente de Mixer.',
  playVideo: 'Reproducir vídeo',
  previousSlide: 'Ir a la diapositiva anterior',
  products: 'Productos',
  productInformation: 'Información del producto',
  promotions: 'Promociones',
  poweredByAzure: 'Desarrollado por Azure',
  purchaseInfo: 'Información de compra',
  readMore: 'Saber más',
  reload: 'recargar',
  reloadToTryAgain: 'Vuelva a cargar para volver a intentarlo',
  selectDay: 'Seleccionar día',
  selectMonth: 'Seleccionar mes',
  selectYear: 'Seleccionar año',
  share: 'Cuota',
  shareDescription:
    'Comparte esta experiencia multimedia y esta página en línea con alguien que conozcas.',
  shareDialogTitle: '¡Echa un vistazo a estas increíbles características disponibles con {title}!',
  shopGame: 'Comprar {title}',
  shopNow: 'Comprar ahora',
  shopSku: 'Comprar {title}',
  showMore: 'Mostrar más',
  showLess: 'Mostrar menos',
  showcase: 'Presentar',
  streamEnded: 'Este contenido en streaming ha finalizado. Se está cargando contenido a petición.',
  timeline: 'Cronología',
  timeStampJumpTo: 'Saltar a',
  timeStampMore: 'Más',
  tips: 'Consejos',
  unknownError: '¡Vaya! Algo salió mal al cargar el reproductor de video',
  video: 'video',
  voice: 'Voz: ',
  view: 'Vista',
  watchNow: 'Ver ahora',
  windows: 'Windows 11',
  year: 'Año',
};
