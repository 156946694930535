import defu from 'defu';
import Plausible from 'plausible-tracker';
import { inject } from 'vue';

function loadScript(source, options = {}) {
  return new Promise((resolve, reject) => {
    const head = document.head || document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    const { src, type = 'text/javascript', defer = false, async = false, ...restAttrs } = options;
    script.type = type;
    script.defer = defer;
    script.async = async;
    script.src = src || source;
    script.setAttribute('data-domain', options['data-domain']);

    Object.keys(restAttrs).forEach(key => {
      script[key] = restAttrs[key];
    });

    head.appendChild(script);
    script.onload = resolve;
    script.onerror = reject;
  });
}

function createPlausible(options) {
  const plausible = {
    install(app) {
      const data = defu(options.init, {
        apiHost: 'https://analytics.xbox-interactive.com',
        enableAutoPageviews: true,
      });
      const plausible = Plausible(data);

      if (options.settings.enableAutoPageviews === true) plausible.enableAutoPageviews();

      if (options.settings.enableAutoOutboundTracking === true)
        plausible.enableAutoOutboundTracking();

      loadScript(
        `${data.apiHost}/js/script.${!options.settings.enableAutoPageviews && 'manual.'}js`,
        {
          defer: true,
          'data-domain': data.domain || 'https://analytics.xbox-interactive.com',
        }
      );

      app.config.globalProperties.$plausible = plausible;
      app.provide('$plausible', plausible);
    },
  };
  return plausible;
}

function usePlausible() {
  const plausible = inject('$plausible');

  return {
    ...plausible,
  };
}

export { createPlausible, usePlausible };
